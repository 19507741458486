/**
 * Sticky Header
 */
export default function () {
	const h = document.getElementById('header');
	const doc = document.documentElement;

	let prevScroll = window.scrollY || doc.scrollTop;
	let curScroll;
	let delta = 300;
	let direction = 0;
	let prevDirection = 0;

	const toggleHeader = (direction, curScroll) => {
		if (direction === 1) {
			doc.classList.remove('header-hide');
			prevDirection = direction;
		} else if (direction === 2 && curScroll > delta) {
			doc.classList.add('header-hide');
			prevDirection = direction;
		}
	};

	const onScroll = () => {
		curScroll = window.scrollY || doc.scrollTop;
		if (curScroll > prevScroll) {
			direction = 2;
		} else if (curScroll < prevScroll) {
			direction = 1;
		}

		if (direction !== prevDirection) {
			toggleHeader(direction, curScroll);
		}

		prevScroll = curScroll;
	};

	const setHeight = () => doc.style = `--wp--header--height:${h.offsetHeight}px`;

	window.addEventListener('load', setHeight);
	window.addEventListener('resize', am2.fn.debounce(setHeight, 100));
	window.addEventListener('scroll', am2.fn.debounce(onScroll, 5));
};