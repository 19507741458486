import initPSWP from '../../plugins/am2-PhotoSwipe';

/**
 * Handle PSWP
 */
export default (function (am2) {
	// eslint-disable-next-line func-names
	return function () {
		const dateNow = new Date().toDateString();
		const jsPath = `${am2.templateDirectory}/resources/js/vendor/photoswipe.min.js`;
		const jsPathUI = `${am2.templateDirectory}/resources/js/vendor/photoswipe-ui-default.min.js`;

		am2.fn.loadScript('photoswipe', jsPath, () => {
			am2.fn.loadScript('photoswipe-ui', jsPathUI, () => {
				const pswps = [...document.querySelectorAll('[data-plugin-pswp]')];
				let i;
				// eslint-disable-next-line no-plusplus
				for (i = 0; i < pswps.length; i++) {
					initPSWP(`#${pswps[i].getAttribute('id')}`);
				}
			}, dateNow);
		}, dateNow);
	};
}).apply(this, [window.am2]);
