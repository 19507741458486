/**
 * Newsletter Submit
 * @author Bican Marian Valeriu
 * @version 0.2
 */
export default (function (wecodeart) {

	let _defaults = {
		DOM: {},
		provider: {
			action: 'subscribe',
		},
		type: 'frontend',
		server: '',
		beforeSubmit: () => {
		},
		afterSubmit: () => {
		},
		classes: {
			loading: 'has-js-action'
		}
	};

	const { Component } = am2;

	class Newsletter extends Component {
		/**
		 * Construct Animate instance
		 * @constructor
		 * @param {Element} el
		 * @param {Object} options
		 */
		constructor(el, options) {
			super(Newsletter, el, options);
			this.el = el;
			this.el.Newsletter = this;
			/**
			 * Options for the animation
			 * @member YTIframe#options
			 */
			this.options = Object.assign({}, Newsletter.defaults, {
				server: el.getAttribute('action') !== '' ? el.getAttribute('action') : null,
				DOM: {
					el,
					submit: el.querySelector('button[type="submit"]'),
					loader: el.querySelector('button[type="submit"] svg')
				}
			}, options);
			Newsletter._elements.push(this);
			this._setupEventHandlers();
		}

		static get defaults() {
			return _defaults;
		}

		static init(els, options) {
			return super.init(this, els, options);
		}

		/**
		 * Get Instance
		 */
		static getInstance(el) {
			let domElem = !!el.jquery ? el[0] : el;
			return domElem.Newsletter;
		}

		/**
		 * Teardown component
		 */
		destroy() {
			if (Newsletter.getInstance(this.el)) {
				this._removeEventHandlers();
				let index = Newsletter._elements.indexOf(this);
				Newsletter._elements.splice(index, 1);
				this.el.Newsletter = undefined;
			}
		}

		/**
		 * Setup Events
		 */
		_setupEventHandlers() {
			this._handleSubmitBound = this.sendForm.bind(this);
			const { DOM: { submit } } = this.options;
			submit.addEventListener('click', this._handleSubmitBound);
		}

		/**
		 * Remove Event Handlers
		 */
		_removeEventHandlers() {
			if (Newsletter.getInstance(this.el)) {
				const { DOM: { submit } } = this.options;
				submit.removeEventListener('click', this._handleSubmitBound);
			};
		}

		/**
		 * AJAX Submit MC Form
		 */
		sendForm() {
			if (this.isSending) return;
			const { DOM, classes, type } = this.options;

			DOM.el.addEventListener('submit', e => {
				e.preventDefault();
				e.stopPropagation();
			});

			var beforeSubmit = this.options['beforeSubmit'];
			if (beforeSubmit(DOM.el) === false) return;

			if (type === 'frontend') this._ajaxGET(DOM, classes);
			if (type === 'backend') this._ajaxPOST(DOM, classes);
		}

		_ajaxGET(DOM, classes) {
			jQuery.ajax({
				type: 'GET',
				url: this.options.server,
				data: jQuery(DOM.el).serialize(),
				cache: false,
				dataType: 'jsonp',
				jsonp: 'c',
				contentType: 'application/json; charset=utf-8',
				beforeSend: () => {
					this.isSending = true;
					DOM.loader.classList.add(classes.loading);
				},
				success: data => {
					console.log(data);
					this.isSending = false;
					DOM.loader.classList.remove(classes.loading);
					const afterSubmit = this.options['afterSubmit'];
					const response = { data, success: true };
					return afterSubmit(response, DOM.el);
				},
				error: data => console.log(data)
			});
		}

		_ajaxPOST(DOM, classes) {

			const mailing_provider = DOM.el.querySelector('input[name="mailing_provider"]').value;
			const mailing_list = DOM.el.querySelector('input[name="mailing_list"]').value;
			const nonce = DOM.el.querySelector('input[name="nonce"]').value;
			const email = DOM.el.querySelector('input[name="MERGE0"]').value ||
				DOM.el.querySelector('input[name="EMAIL"]').value;

			const formData = jQuery(DOM.el).serializeArray();
			const paramObj = {};

			formData.filter(item => {
				if (['mailing_provider', 'mailing_list', 'nonce', 'MERGE0', 'EMAIL'].includes(item.name)) return;
				paramObj[item.name] = item.value;
			});

			const data = {
				action: 'am2_newsletter_submission',
				type: this.options.provider.action,
				mailing_provider,
				mailing_list,
				nonce,
				email,
				fields: paramObj
			};

			jQuery.ajax({
				data,
				type: 'post',
				url: am2.ajax,
				beforeSend: () => {
					this.isSending = true;
					DOM.submit.classList.add(classes.loading);
				},
				success: response => {
					this.isSending = false;
					DOM.submit.classList.remove(classes.loading);
					const afterSubmit = this.options['afterSubmit'];
					return afterSubmit(response, DOM.el);
				},
				failure: response => {
					this.isSending = false;
					DOM.submit.classList.remove(classes.loading);
					const afterSubmit = this.options['afterSubmit'];
					return afterSubmit(response, DOM.el);
				}
			});
		}
	}

	/**
	 * @static
	 * @memberof Newsletter
	 */
	Newsletter._elements = [];
	am2.Newsletter = Newsletter;

}).apply(this, [window.am2]);