/**
 * Export default Block Accordion JS
 * @uses {
 * - pluginAccordion
 * }
 * @description Since most of the JS on this file is not needed ASAP, we lazy load this as a route
 */
import PluginAccordion from '../plugins/accordion';

export default (function () {
	const accordions = [...document.querySelectorAll('[data-plugin-accordion]')];
	let i;
	// eslint-disable-next-line no-plusplus
	for (i = 0; i < accordions.length; i++) {
		PluginAccordion(am2.fn.getOptions(accordions[i].dataset.options));
	}
});
