/* eslint linebreak-style: ["error", "windows"] */
/**
 * Check if client is on IE/Edge Browser
 * @return  {bolean}
 */
export default () => {
	const ua = window.navigator.userAgent;
	const msie = ua.indexOf('MSIE ');
	const trident = ua.indexOf('Trident/');
	if (msie > 0 || trident > 0) return true;
	return false;
};

export const isEDGE = () => {
	const ua = window.navigator.userAgent;
	const edge = ua.indexOf('Edge/');
	if (edge > 0) return true;
	return false;
};
