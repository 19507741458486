/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
function getFacebookCount(url, selector) {
	const elem = selector.querySelector('.js-count-facebook');
	// This should be replaced with client own APP_ID|APP_SECRET
	const token = '918168974866485|6c9a824fcf873469ac275f910c3085bb';
	jQuery.ajax({
		url: 'https://graph.facebook.com/v3.0/',
		dataType: 'jsonp',
		type: 'GET',
		data: { fields: 'engagement', access_token: token, id: url },
		success(data) {
			/* eslint-disable camelcase */
			const { engagement: { share_count = 0 } } = data;
			elem.innerHTML = share_count;
		},
		error() {
			elem.innerHTML = 0;
		},
	});
}

/* Twitter might have canceled official API for counting url tweets */
/* function getTwitterCount(url, selector) {
  const elem = selector.querySelector('.js-count-twitter');
  $.ajax({
	url: 'https://cdn.api.twitter.com/1/urls/count.json',
	dataType: 'jsonp',
	type: 'GET',
	data: { url, callback: '' },
	success(data) {
	  elem.innerHTML = data;
	},
	error() {
	  elem.innerHTML = 0;
	},
  });
} */

function getPinterestCount(url, selector) {
	const elem = selector.querySelector('.js-count-pinterest');
	jQuery.ajax({
		url: 'https://api.pinterest.com/v1/urls/count.json',
		dataType: 'jsonp',
		type: 'POST',
		data: { url },
		success(data) {
			const { count = 0 } = data;
			elem.innerHTML = count;
		},
		error() {
			elem.innerHTML = 0;
		},
	});
}

export default function () {
	let elem;
	const socialCountElems = document.querySelectorAll('[data-social-count-url]');
	if (socialCountElems.length > 0) {
		/* eslint-disable no-plusplus */
		for (elem = 0; elem < socialCountElems.length; elem++) {
			const socialCnt = socialCountElems[elem];
			const socialUrl = socialCnt.getAttribute('data-social-count-url');
			getFacebookCount(socialUrl, socialCnt);
			/* getTwitterCount(socialUrl, socialCnt); */
			getPinterestCount(socialUrl, socialCnt);
		}
	}
}
