/* eslint linebreak-style: ["error", "windows"] */
/**
 * Generate String Params
 * @param  {object}
 * @return {string}
 */
export default function (query) {
	return Object.keys(query).map((key) => {
		const encoded = [key, query[key]].map(encodeURIComponent).join('=');
		return encoded;
	}).join('&');
}
