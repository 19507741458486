/**
 * Handle Newsletter Init
 */
export default (function (am2) {
	return function () {
		// Newsletter
		const mcForms = document.querySelectorAll('[data-plugin-newsletter]');
		am2.Newsletter.init(mcForms, {
			beforeSubmit: (formEl) => {
				const isValid = formEl.checkValidity();
				if (isValid === false) formEl.classList.add('was-validated');
				return isValid;
			},
			afterSubmit: (response, formEl) => {
				formEl.classList.add('submitted');
				formEl.classList.remove('was-validated');
				const { data: { msg } } = response;
				const msgCont = formEl.querySelector('.newsletter-form__msg');
				msgCont.innerHTML = msg;
				msgCont.classList.remove('d-none');
				setTimeout(() => {
					msgCont.innerHTML = '';
					msgCont.classList.add('d-none');
				}, 8000);
			},
		});
	};
}).apply(this, [window.am2]);
