/**
 * Export default Block Tabs JS
 * @uses {
 * - pluginAccordion
 * }
 * @description Since most of the JS on this file is not needed ASAP, we lazy load this as a route
 */
import PluginTabs from '../plugins/tabs';

export default (function (am2) {
	(() => {
		const tabs = [...document.querySelectorAll('[data-plugin-tabs]')];
		let i;
		// eslint-disable-next-line no-plusplus
		for (i = 0; i < tabs.length; i++) {
			PluginTabs(am2.fn.getOptions(tabs[i].dataset.options));
		}
	})();
}).apply(this, [window.am2]);
