import _pickBy from 'lodash/pickBy';
import _filter from 'lodash/filter';
import camelCase from './helpers/camelCase';

/**
 * Utility to trigger JS code based on clasname routing
 */
export default class JSManager {
	/**
	 * Generic constructor
	 * @constructor
	 * @param {object} namespace | JS Object
	 * @param {string} routes | Key name containing routes
	 */
	constructor(namespace, routes = 'routes') {
		this.namespace = namespace; // WP Localized head obj
		this.routesKey = routes;
		this.routesDir = this.namespace.templateDirectory + '/assets/dev/js/' + this.routesKey;
		this.extendedR = _pickBy(
			this.namespace[this.routesKey],
			v => v.extends && v.extends instanceof Array,
		);
		if (typeof this.namespace.assetsLoaded === 'undefined') this.namespace.assetsLoaded = [];
		if (typeof this.namespace.functionsQueue === 'undefined') this.namespace.functionsQueue = [];
	}

	// eslint-disable-next-line consistent-return
	fireRoute(route, funcname, args) {
		let fire;
		document.dispatchEvent(new CustomEvent('am2/routed', {
			bubbles: true,
			detail: { route, fn: funcname },
		}));

		funcname = funcname === undefined ? 'init' : funcname;
		fire = route !== '';
		fire = fire && this.namespace[this.routesKey][route];
		fire = fire && typeof this.namespace[this.routesKey][route][funcname] === 'function';

		if (fire) return this.namespace[this.routesKey][route][funcname](args);
	}

	/**
	 * Meant to be run on load
	 * @info It handles all necessary JS init from routes
	 */
	loadEvents() {
		this.fireRoute('common');
		for (const cls of document.body.classList) {
			const route = camelCase(cls.toLowerCase().replace(/-/g, '_'));

			// Fire Manual Routes
			this.fireRoute(route);
			this.fireRoute(route, 'complete');

			// Additional Extended Routes
			_filter(this.extendedR, (v, k) => v.extends.includes(cls) && this.fireRoute(k));
		}
		this.fireRoute('common', 'complete');
	}

	createScript(options) {
		const script = document.createElement('script');
		let { url } = options;
		if (options.hash.length) url = url.concat('?ver=', options.hash);
		script.type = options.type || 'text/javascript';
		script.async = !!options.async;
		script.id = options.id;
		script.src = url;
		return script;
	}

	getScriptById(id) {
		return (id && document.getElementById(id));
	}

	getScriptFromObj(id) {
		return ((this.namespace.assetsEnqueue.indexOf(id) > -1));
	}

	executeFnQueue(key, queueArray) {
		while (queueArray[key].length > 0) (queueArray[key].shift())();
	}

	/**
	 * Used to lazyload any script/route
	 * @param {string}    id       The id of the script
	 * @param {string}    url      The url of the script
	 * @param {function}  callback The callback to be run after the script is loaded
	 * @param {string}    hash     Random chars used for creating cache hash version
	 */
	loadScript(id, url, callback, hash = false) {
		const foundScript = this.getScriptFromObj(id) || this.namespace.assetsLoaded[id] === true;
		if (foundScript && typeof callback === 'function') return callback();
		if (typeof this.namespace.functionsQueue[id] === 'undefined') this.namespace.functionsQueue[id] = [];

		if (callback && typeof callback === 'function') this.namespace.functionsQueue[id].push(callback);

		if (this.getScriptById(id)) return false;

		const body = document.getElementsByTagName('body')[0] || document.documentElement;
		const script = this.createScript({ id, url, hash });

		// eslint-disable-next-line no-console
		script.onerror = () => console.warn(`JSManager - the url for script id '${id}' was not resolved. Please check.`);

		script.onload = script.onreadystatechange = () => {
			const isReady = (!script.readyState || script.readyState === 'loaded' || script.readyState === 'complete');
			if (!this.namespace.assetsLoaded[id] && isReady) {
				// Handle memory leak in IE
				script.onload = script.onreadystatechange = null;
				this.namespace.assetsLoaded[id] = true;
				this.executeFnQueue(id, this.namespace.functionsQueue);
			}
		};

		// Fire the script
		return body.appendChild(script);
	}
}

export const loadScript = (id, url, callback, hash) => {
	const JSM = new JSManager(window.am2);
	JSM.loadScript(id, url, callback, hash);
};
