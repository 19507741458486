/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
/**
 * Tabs Plugin
 * @param {object/string} opts
 * @todo - https://ba.northernbadger.co.uk/ba.html#options
 */
export default (args) => {
	const defaults = {
		animationTime: 300,
	};

	const options = Object.assign({}, defaults, args);

	// Set first tab active
	jQuery(`#${options.blockId} .am2-tabs__title:first-child`).addClass('am2-tabs__title--current');
	jQuery(`#${options.blockId} .am2-tabs__panel:first-child`).addClass('am2-tabs__panel--current');

	// Get current tab from URL
	const hash = $.trim(window.location.hash);
	const $blockId = hash.split('-');
	if (hash) {
		const $index = jQuery(`#am2-tabs-${$blockId[1]} .am2-tabs__link[href=${hash}]`).parent().index();
		jQuery(`#am2-tabs-${$blockId[1]} .am2-tabs__title`).removeClass('am2-tabs__title--current');
		jQuery(`#am2-tabs-${$blockId[1]} .am2-tabs__panel`).removeClass('am2-tabs__panel--current');
		jQuery(`#am2-tabs-${$blockId[1]} .am2-tabs__link[href=${hash}]`).parent().addClass('am2-tabs__title--current');
		jQuery(`#am2-tabs-${$blockId[1]} .am2-tabs__panel`).eq($index).addClass('am2-tabs__panel--current');
	}

	// AM2 Tabs
	jQuery(`#${options.blockId}`).on('click', '.am2-tabs__title', function () {
		const $that = jQuery(this);
		if (!jQuery(this).hasClass('am2-tabs__title--current')) {
			jQuery(`#${options.blockId} .am2-tabs__title`).removeClass('am2-tabs__title--current');
			jQuery(`#${options.blockId} .am2-tabs__panel`).fadeOut(options.animationTime).promise().done(() => {
				jQuery(`#${options.blockId} .am2-tabs__panel`).removeClass('am2-tabs__panel--current');
				jQuery(`#${options.blockId} .am2-tabs__panels`)
					.children('.am2-tabs__panel')
					.eq($that.index())
					.fadeIn(options.animationTime)
					.addClass('am2-tabs__panel--current');
			});

			jQuery(this).addClass('am2-tabs__title--current');
		}
	});
};
