/* eslint linebreak-style: ["error", "windows"] */
import checkIE, { isEDGE } from '../../helpers/checkIE';
/**
 * Handle Classes
 */
export default function () {
	const html = document.documentElement;
	const { body } = document;

	html.classList.remove('no-js');
	html.classList.add('js');

	if (checkIE()) body.classList.add('is-IE');
	if (isEDGE()) body.classList.add('is-EDGE');
}
