export default (function (am2) {
jQuery(document).ready(function ($) {
    $(document).on('click', '.ticket-card__item-button', function () {
        const ticketLink = $(this).attr('data-link');
        $('#ticket-popup').attr('src', ticketLink);
        setTimeout(() => {
            $('#ticket-popup').addClass('active');
            $('body').addClass('overflow-hidden');
        }, 300);
        setTimeout(() => {
            $('#ticket-close').addClass('active');
        }, 500);
    })
    $(document).on('click', '#ticket-close', function () {
        $(this).removeClass('active');
        $('body').removeClass('overflow-hidden');
        $('#ticket-popup').removeClass('active');
    })
});
});