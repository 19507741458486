/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
/**
 * Match Height
 */


export default function () {

	const jsPath = `${am2.templateDirectory}/resources/js/vendor/jquery.matchHeight.js`;
	am2.fn.loadScript('matchHeight', jsPath, () => {
		jQuery('[data-mh]').matchHeight();
	});
}
