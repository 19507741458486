/**
 * Export default Single Video
 * @uses {
 * - SocialCount Plugin
 * }
 * @description Since most of the JS on this file is not needed ASAP, we lazy load this as a route
 */
import socialCountPlugin from '../plugins/am2-SocialCount';

// eslint-disable-next-line no-unused-vars
export default (function (am2) {
	// Social
	socialCountPlugin();
});
