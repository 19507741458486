/**
 * Main Component
 * @author  am2Studio
 * @license https://www.am2studio.hr/
 * @description {
 * - Adapted for my plugin setup for @am2Studio
 * }
 */
export default (function (am2) {

	class Component {
		/**
		 * Generic constructor for all components
		 * @constructor
		 * @param {Element} el
		 * @param {Object} options
		*/
		// eslint-disable-next-line no-unused-vars
		constructor(ClassDef, el, options) {
			// Display error if el is valid HTML Element
			if (!(el instanceof Element)) {
				// eslint-disable-next-line no-console
				console.error(Error(`${el} is not an HTML Element`));
			}

			// If exists, destroy and reinitialize in child
			const ins = ClassDef.getInstance(el);
			if (ins) {
				ins.destroy();
			}

			this.el = el;
		}

		/**
		 * Initializes components
		 * @param {class} classDef
		 * @param {Element | NodeList | jQuery} els
		 * @param {Object} options
		 */
		static init(ClassDef, els, options) {
			let instances = null;
			if (els instanceof Element) {
				instances = new ClassDef(els, options);
			} else if (!!els && (els.jquery || els instanceof NodeList)) {
				const instancesArr = [];
				const { fn: { getOptions } } = am2;
				// eslint-disable-next-line no-plusplus
				for (let i = 0; i < els.length; i++) {
					// eslint-disable-next-line no-undef
					const mergedOptions = Object.assign({}, options, getOptions(els[i].dataset.options));
					instancesArr.push(new ClassDef(els[i], mergedOptions));
				}
				instances = instancesArr;
			}

			return instances;
		}
	}

	/**
	 * @static
	 * @memberof Component
	 */
	am2.Component = Component;

}).apply(this, [window.am2]);