/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
import googleStyles from '../helpers/googleStyles';

export default function (args) {
	const defaults = {
		zoom: 15,
		scrollwheel: false,
		draggable: false,
	};

	const options = Object.assign({}, defaults, args);

	const map = new google.maps.Map(document.getElementById(options.blockId), {
		zoom: options.zoom,
		scrollwheel: options.scrollwheel,
		draggable: options.draggable,
		center: new google.maps.LatLng(options.lat, options.lng),
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		styles: googleStyles,
	});

	const infowindow = new google.maps.InfoWindow({ maxWidth: 350 });

	const marker = new google.maps.Marker({
		position: new google.maps.LatLng(options.lat, options.lng),
		map,
		icon: `${am2.templateDirectory}/resources/svg-builder/icon/pin.svg`,
		animation: google.maps.Animation.DROP,
	});

	google.maps.event.addListener(marker, 'click', (() => {
		infowindow.setContent(`<p class="am2-map__info">${options.address}</p>`);
		infowindow.open(map, marker);
	})(marker));
}
