/* eslint-disable func-names */
/* eslint linebreak-style: 0 */
/**
 * Export default Block Slider JS
 * @uses {
 * - pluginGoogleMaps
 * }
 * @description Since most of the JS on this file is not needed ASAP, we lazy load this as a route
 */
import PluginGoogleMaps from '../plugins/googleMaps';

export default (function () {
	const { google: { apiKey } } = am2;
	// eslint-disable-next-line no-console
	if (apiKey === '') return console.warn('Please setup an API key for using Google Maps.');
	const jsPath = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;

	am2.fn.loadScript('google-maps', jsPath, () => {
		const maps = [...document.querySelectorAll('[data-plugin-gmaps]')];
		let i;
		// eslint-disable-next-line no-plusplus
		for (i = 0; i < maps.length; i++) {
			const options = am2.fn.getOptions(maps[i].dataset.options);
			const blockId = maps[i].getAttribute('id');
			if (blockId) {
				options.blockId = blockId;
				PluginGoogleMaps(options);
			}
		}
	});
	return false;
});
