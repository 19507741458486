export default function () {
	window.onload = function () {
		const zone = 'America/Vancouver';
		const interval = 1000;
		const clock = $('[data-countdown]');

		const eventTime = moment(clock.data('countdown')).unix();
		const currentTime = moment().tz(zone).unix();
		const diffTime = eventTime - currentTime;
		let duration;
		duration = moment.duration(diffTime * 1000, 'milliseconds');

		if (diffTime > 0) {
			setInterval(() => {
				duration = moment.duration(duration.asMilliseconds() - interval, 'milliseconds');
				let mo = moment.duration(duration).months();
				let d = moment.duration(duration).days();
				let h = moment.duration(duration).hours();
				let m = moment.duration(duration).minutes();
				let s = moment.duration(duration).seconds();

				mo = $.trim(mo).length === 1 ? `0${mo}` : mo;
				d = $.trim(d).length === 1 ? `0${d}` : d;
				h = $.trim(h).length === 1 ? `0${h}` : h;
				m = $.trim(m).length === 1 ? `0${m}` : m;
				s = $.trim(s).length === 1 ? `0${s}` : s;

				if (mo !== '00') {
					if (!clock.children().first().hasClass('countdown__months')) {
						$('<span class="countdown__months"></span>').prependTo($('[data-countdown]'));
					}
					$('.countdown__months').text(mo);
				}

				$('[data-countdown-days]').text(d);
				$('[data-countdown-hours]').text(h);
				$('[data-countdown-minutes]').text(m);
				$('[data-countdown-seconds]').text(s);
			}, interval);
		}
	};
}
