/**
 * Handle Slide Change
 */
const handleSlideChange = () => {
	const slicks = [...document.querySelectorAll('[data-plugin-slick]')];
	let i;
	for (i = 0; i < slicks.length; i++) {
		const $item = jQuery(slicks[i]);
		$item.on('init afterChange', (event, slick, currentSlide, nextSlide) => {
			console.log('Slick has Init or Slide has been changed');
		});

		$item.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
			console.log('Slick slide will change.');
		});
	}
};

/**
 * Default
 */
export default (function (am2, $) {
	return function () {
		// Lazy Load Slick
		const jsPath = `${am2.templateDirectory}/resources/js/vendor/slick.min.js`;
		am2.fn.loadScript('slick-js', jsPath, () => {
			const slicks = [...document.querySelectorAll('[data-plugin-slick]')];
			let i;
			for (i = 0; i < slicks.length; i++) {
				$(slicks[i]).not('.slick-initialized').slick(am2.fn.getOptions(slicks[i].dataset.options));
			}
		});
	};
	// eslint-disable-next-line no-undef
}).apply(this, [window.am2, jQuery]);
