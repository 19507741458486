/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
/**
 * Mobile Menu JS
 */
export default function () {
	const $respMenu = jQuery('.menu--responsive-menu');
	const $body = jQuery('body');
	const triggerSelectors = [
		'.js-menu-toggle',
	].join(', ');
	const $triggers = jQuery(triggerSelectors);

	let containingElement = document.querySelector('.site-offcanvas');

	jQuery(window).on('click', (e) => {
		if (containingElement.contains(e.target) || $body.hasClass('menu-open') === false) {
			return;
		}

		$body.removeClass('menu-open');
		$triggers.removeClass('menu-toggle--active');
	});

	$triggers.on('click', (e) => {
		e.stopPropagation();

		$body.toggleClass('menu-open');
		$triggers.toggleClass('menu-toggle--active');
	});

	// Add arrows toggles on mobile
	$respMenu.find('.menu-item__dropdown').each(function () {
		const arrow = [
			'<button class="menu-item__toggle">',
			'<svg class="arrow-down" viewBox="0 0 307.054 307.054">',
			'<g><path d="M302.445,205.788L164.63,67.959c-6.136-6.13-16.074-6.13-22.203,',
			'0L4.597,205.788c-6.129,6.132-6.129,16.069,0,22.201l11.101,11.101c6.129,',
			'6.136,16.076,6.136,22.209,0l115.62-115.626L269.151,239.09c6.128,6.136,16.07,',
			'6.136,22.201,0l11.101-11.101C308.589,221.85,308.589,211.92,302.445,205.788z"/></g></svg>',
			'</button>',
		].join('');
		jQuery(this).prev().append(arrow);
	});

	// Handle Arrow Functionality
	jQuery('.menu-item__toggle').on('click', function (e) {
		e.preventDefault();
		jQuery(this).parent()
			.parent()
			.siblings()
			.find('.menu-item__dropdown')
			.slideUp(300)
			.prev()
			.children()
			.removeClass('rotate-arrow');
		jQuery(this).toggleClass('rotate-arrow').parent()
			.next()
			.slideToggle(300);
	});
}
