/**
 * Youtube Iframes
 * @author 	Bican Marian Valeriu
 * @version 0.1
 */
export default (function (am2) {
	let _defaults = {
		minWidth: 991,
		videoID: '',
		srcVars: {
			rel: '0',
			showinfo: '0',
			controls: '0',
			mute: '0',
			autoplay: '0',
			hd: '1',
			loop: '0',
			playlist: ''
		},
		preloadDelay: 500,
		triggerSelector: '[data-plugin-youtube]',
		classes: {
			ratio: 'widescreen',
			loaded: 'loaded',
			loading: 'loading'
		}
	};

	const { Component } = am2;

	class YTIframe extends Component {
		/**
		 * Construct Animate instance
		 * @constructor
		 * @param {Element} el
		 * @param {Object} options
		 */
		constructor(el, options) {
			super(YTIframe, el, options);
			this.el = el;
			this.el.YTIframe = this;
			/**
			 * Options for the animation
			 * @member YTIframe#options
			 */
			this.options = Object.assign({}, YTIframe.defaults, { containerSelector: el }, options);
			YTIframe._elements.push(this);
			this._setupEventHandlers();
		}

		static get defaults() {
			return _defaults;
		}

		static init(els, options) {
			return super.init(this, els, options);
		}

		/**
		 * Get Instance
		 */
		static getInstance(el) {
			let domElem = !!el.jquery ? el[0] : el;
			return domElem.YTIframe;
		}

		/**
		 * Teardown component
		 */
		destroy() {
			if (YTIframe.getInstance(this.el)) {
				this._removeClasses();
				this._removeEventHandlers();
				let index = YTIframe._elements.indexOf(this);
				YTIframe._elements.splice(index, 1);
				this.el.YTIframe = undefined;
			}
		}

		_removeClasses() {
			// IE 11 bug (can't remove multiple classes in one line)
			this.el.classList.remove(self.options.classes.loaded);
		}

		/**
		 * Setup Events
		 */
		_setupEventHandlers() {
			if (this.options.minWidth >= window.innerWidth) return;

			this._handleLoadBound = this.createFrame.bind(this);
			if (this.options.triggerSelector === false) {
				document.addEventListener('DOMContentLoaded', this._handleLoadBound());
				return;
			}

			if (this.options.triggerSelector === 'event') return;

			document.querySelector(this.options.triggerSelector).addEventListener('click', this._handleLoadBound);
		}

		/**
		 * Remove Event Handlers
		 */
		_removeEventHandlers() {
			if (YTIframe.getInstance(this.el)) {
				if (this.options.triggerSelector === false) {
					document.removeEventListener('DOMContentLoaded', this._handleLoadBound);
					return;
				}
				document.querySelector(this.options.triggerSelector).removeEventListener('click', this._handleLoadBound);
			};
		}

		createFrame() {
			let self = this;
			let iframe = document.createElement("iframe");
			let params = self.options.srcVars;
			let YTVars = am2.fn.createParams(params);

			let source = [
				'//www.youtube.com/embed/',
				self.options.videoID,
				'?start=1&enablejsapi=1&origin=',
				window.location.host,
				'&' + YTVars
			].join('');

			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("id", "video-player");
			iframe.setAttribute("allowfullscreen", "");
			iframe.setAttribute('allow', 'autoplay');
			iframe.setAttribute("src", source);

			if (self.options.triggerSelector !== false) {
				// Remove all if we have a trigger like a play button
				while (self.el.firstChild) self.el.removeChild(self.el.firstChild);
			}

			// Add Classes
			self.el.classList.add(self.options.classes.loading);
			self.el.appendChild(iframe);
			self.el.classList.add(self.options.classes.loaded);

			setTimeout(() => {
				// Replace all except iframe
				while (self.el.childNodes.length > 1) self.el.removeChild(self.el.firstChild);
				self.el.classList.remove(self.options.classes.loading);
			}, self.options.preloadDelay);
		}
	}

	/**
	 * @static
	 * @memberof stickMe
	 */
	YTIframe._elements = [];
	am2.YTIframe = YTIframe;

}).apply(this, [window.am2]);