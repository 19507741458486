/**
 * ===========================
 * @author:  am2Studio      ==
 * @package: JS Bundle      ==
 * @version: 2.0.0          ==
 * ===========================
 *
 * @description: {
 * --- @usage {
 * a) any file create into /routes/ directory will be auto picked up by webpack and will generate
 * a new entry, for code splitting ( note: subfolders are intended to be ignored )
 * EG: you have a popup plugin/tooltips and other js that runs on user clicks or is needed
 * under viewport - use this to code split your JS for improving the performance of the site a bit
 * b) routes generated can be enqueued with wp_enqueue inside assets or lazyloaded
 * }
 * --- @naming {
 * a) any plugin we create goes to /plugins/ folder
 * b) any helper functions goes to /helpers/ folder (debounce, parseData etc)
 * c) route specific js goes to /routes/your-route-name.js
 * while its functions/classes/etc goes into a subfolder with same name
 * }
 *
 * @JSManager { The class that handles JS loading/running
 * - common js init key runs first
 * - route specific js init/complete keys runs after (same order)
 * - common js complete key runs after
 * - JSManager.loadScript() - runs last since is async
 * }
 *
 * @loadScript {
 * --- @arguments {
 * 1 -> handle name/script id
 * 2 -> script path
 * 3 -> callback ( function to run after the script is loaded - not required)
 * 4 -> string for cache busting ( use new Date() )
 * }
 * --- @usage {
 * a) loadScript - lazy loads a piece of js by first checking into am2.assetsEnqueue key
 * b) if the script is already enqueued using WP, your callback will run imediately
 * c) if the script is not found above, it will create it, load it then run callback
 * d) second call of this, with the same handle name will only run CB
 * }
 */

import './../scss/style.scss';

// JSManager and Helpers
import _JSManager, { loadScript } from './am2-JSManager';
import helperDebounce from './helpers/debounce';
import helperParseData from './helpers/parseData';
import helperGenParams from './helpers/createParams';

// Common JS - loaded on all pages
import handleMobileMenu from './routes/common/mobileMenu';
import handleStickyHeader from './routes/common/stickyHeader';
import handleBodyClasses from './routes/common/handleBodyClasses';
import handleMatchHeight from './routes/common/handleMatchHeight';
import handleCountdown from './routes/blocks/countdown';
import handlePSWPInit from './routes/common/handlePhotoSwipe';
import initNewsletter from './routes/common/initNewsletter';
import initSliders from './routes/blocks/slider';

// Plugins
import './plugins/am2-Component';
import './plugins/am2-Newsletter';
import './plugins/am2-YTIframe';

// Blocks
import accordion from './routes/block-accordion';
import map from './routes/block-map';
import tabs from './routes/block-tabs';
import singlePost from './routes/single-post';
import ticketScript from './routes/ticket-scripts';
/**
 * Blocks JS - loaded on specific block (if it exists in body class)
 * @see - class-am2-hooks.php @ body_class() filter function
 * @info - each block will generate a class like: am2-block-{ acf_name } where
 * acf_name is your field name in flexible editor. This class can be used as `am2-block-acf_name`
 * Inside the routes bellow, as a key. Any JS located there will be run only when that block exists
 * @see - bellow you have examples with lazyloaded JS {
 * - this means, that the js will only be loaded on that page if the block exists
 * - if you need to have the JS asap, you can use functions, placed under /routes/route_name/
 * for easier management and then import that functions and use them here
 * }
 */

// Attach Some Required Plugins
am2.fn = {};
am2.fn.debounce = helperDebounce;
am2.fn.getOptions = helperParseData;
am2.fn.createParams = helperGenParams;
am2.fn.loadScript = loadScript;

// Cache Busting Routes
const dateNow = new Date().toISOString();

// Attach Specific Route JS
am2 = {
	...am2,
	...{
		routes: {
			/**
			  * @info
			  * This prop includes the global JS fired on every page
			  * Reserved Key
			  */
			common: {
				/**
				  * Runs first before any JS
				  */
				init: () => {
					/**
					  * Here we have all common js loaded asap on all pages
					  */
					ticketScript();
					handleBodyClasses();
					handleStickyHeader();
					handleMobileMenu();
					handleMatchHeight(); // This should be moved on the block where is used.
					// YT iFrame Lazy Load
					const iframeElems = document.querySelectorAll('[data-plugin-youtube]');
					am2.YTIframe.init(iframeElems);
				},
				complete: () => {
					initSliders();
					initNewsletter();
				},
			},
			// IE JS, runs only if is IE detected
			isIE: { // Route created before using handleBodyClasses() in the common js
				// eslint-disable-next-line no-console
				init: () => console.log('Update this ancient tool.'),
				// eslint-disable-next-line no-alert
				complete: () => alert('Still not updated this browser ?'),
				extends: ['is-EDGE'], // Lets extend if for EDGE Too
			},
			/**
			  * @instructions {
			  * You should not consider creating to many custom routes for each block,
			  * However you can use bellow method to load required JS plugins for its route
			  * Bellow are just example, code splitting routes should be used for bigger routes only
			  * }
			  */
			// Google Maps
			am2BlockMap: {
				init: () => {
					map();
					console.log('Map Loaded');
				},
				extends: ['am2-block-getting_there'],
			},
			// Tabs
			am2BlockTabs: {
				init: () => {
					tabs();
					console.log('Tabs Loaded');
				},
			},
			// Accordion
			am2BlockAccordion: {
				init: () => {
					accordion();
					console.log('Accordion Loaded');
				}, // Should be removed, for demo purposes, you will see this on single-post
				extends: ['single-post'],
			},
			// Countdown
			am2BlockCountdown: {
				init: () => {
					let routePath = `${am2.templateDirectory}/resources/js/vendor/moment.min.js`;
					am2.fn.loadScript('moment-js', routePath, () => {
						routePath = `${am2.templateDirectory}/resources/js/vendor/moment-timezone.min.js`;
						am2.fn.loadScript('moment-tz-js', routePath, () => {
							handleCountdown();
						}, dateNow);
					});
				},
			},
			// Single Post
			singlePost: {
				init: () => {
					singlePost();
					console.log('Single Post Loaded');
				},
			},
			ticketScript: {
				init: () => {
					ticketScript();
				},
			},
			// Performers
			taxPerformerCategory: {
				init: () => {
					handlePSWPInit();
				},
				extends: ['post-type-archive-performer', 'page'],
			},
		},
	},
};

/**
 * Init the awesomeness itself
 */
const JSManager = new _JSManager(am2);
document.addEventListener('DOMContentLoaded', JSManager.loadEvents());