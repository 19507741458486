/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint linebreak-style: ["error", "windows"] */
/**
 * Parse data attrs
 * @param   {object/string} opts
 * @todo - https://ba.northernbadger.co.uk/ba.html#options
 */
export default (args) => {
	const defaults = {
		blockId: 'am2-accordion-1',
		animationTime: 300,
	};

	const options = Object.assign({}, defaults, args);

	// AM2 Accordion
	jQuery(`#${options.blockId}`).on('click', '.am2-accordion__panel-title', function () {
		if (jQuery(this).hasClass('am2-accordion__panel--current')) {
			jQuery(this).removeClass('am2-accordion__panel--current')
				.parent()
				.find('.am2-accordion__panel-content')
				.slideUp(options.animationTime);
		} else {
			const currentAccordionPanel = jQuery(`#${options.blockId} .am2-accordion__panel--current`);
			if (currentAccordionPanel.length > 0) {
				currentAccordionPanel.removeClass('am2-accordion__panel--current')
					.parent()
					.find('.am2-accordion__panel-content')
					.slideUp(options.animationTime);
			}
			jQuery(this).addClass('am2-accordion__panel--current')
				.parent()
				.find('.am2-accordion__panel-content')
				.slideDown(options.animationTime);
		}
	});
};
